import { HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, Router } from '@angular/router'
import { catchError, switchMap, take, tap, BehaviorSubject, Observable, mergeMap, map, of } from 'rxjs'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import { GoogleAnalyticsService } from 'ngx-google-analytics'
import { TokenService } from '../../services/token/token.service'
import { ApiService } from '../../services/api/api.service'
import { OrganizationsService } from '../../services/organizations/organizations.service'
import { UserAgreementsService } from '../../services/user-agreements/user-agreements.service'
import { UserService } from '../../services/user/user.service'
import { StoreService } from '../../services/store/store.service'
import { AuthConfigParams, OnboardGenericData } from '../models/auth.model'
import { ApiErrorResponseV1 } from '../../models/_errors.model'
import { User, AuthUserWrapper } from '../../models/user.model'
import { ReCaptchaV3Service } from 'ng-recaptcha-2'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private api: ApiService,
    private tokenService: TokenService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private organizationsService: OrganizationsService,
    private userService: UserService,
    private agreementsService: UserAgreementsService,
    private storeService: StoreService,
    private gaService: GoogleAnalyticsService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) { }

  private authConfig$: BehaviorSubject<AuthConfigParams> = new BehaviorSubject<AuthConfigParams>({
    auth_method: null,
    org_type: null,
    org_form: null,
    auth_type: null,
    current_step: 0
  })

  /**
   * Returns an object with default authentication configuration values.
   * @returns BehaviorSubject<AuthConfigParams>
   */
  public getAuthConfigDefaults(): Observable<AuthConfigParams> {
    return of({
      auth_method: null,
      org_type: null,
      org_form: null,
      auth_type: null,
      current_step: 0
    })
  }

  /**
   * Returns the current authConfig$ observable
   */
  public getAuthConfig(): BehaviorSubject<AuthConfigParams> {
    return this.authConfig$
  }

  /**
   * Sets new values to authConfig$.
   */
  public setAuthConfig(params: AuthConfigParams) {
    this.authConfig$.next(params)
  }

  /**
 * Auth using email and password
 * */
  public eidSubmitEmailAuth(eid: string, body) {
    return this.recaptchaV3Service.execute('authEmailSubmit')
      .pipe(
        switchMap(recaptchaToken => {
          let headers = new HttpHeaders()
            .set('g-recaptcha-response', recaptchaToken)
          return this.api.eidSubmitEmailAuth(eid, body, headers)
        })
      )
  }

  public loginUserObservable(transaction_id, fakeSSN: string | null = null): Observable<OnboardGenericData> {
    return this.getLoginAuthUserWrapper(transaction_id, fakeSSN)
      .pipe(
        switchMap(user => this.getExtendedOrganizationData(user)),
        tap(() => {
          let redirectUrlParam: string = this.route.snapshot.queryParams.redirectUrl

          if (redirectUrlParam && redirectUrlParam.includes('logout')) {
            redirectUrlParam = null
          }

          const redirectUrl = redirectUrlParam || '/dashboard'
          this.router.navigate([redirectUrl], { queryParamsHandling: 'merge' })
        })
      )
  }

  private getExtendedOrganizationData(user: User): Observable<OnboardGenericData> {
    return this.organizationsService.getExtendedOrganizationData(of(user))
  }

  public getLoginAuthUserWrapper(transaction_id: string, fakeSSN: string | null = null): Observable<User> {
    const body = { transaction_id }
    let headers = new HttpHeaders()

    if (fakeSSN) {
      headers = headers.append('fakeSSN', fakeSSN.toString())
    }

    return this.recaptchaV3Service.execute('authShoutlyLogin')
      .pipe(
        switchMap(recaptchaToken => {
          headers = headers.append('g-recaptcha-response', recaptchaToken)
          return this.api.loginUser(body, headers)
        }),
        catchError((err: ApiErrorResponseV1) => {
          this.snackBar.open(_(err.error.err_mess), null, { panelClass: ['shoutly-snack-bar', 'error'] })
          throw err
        }),
        tap((data: AuthUserWrapper) => this.tokenService.setJwtToken(data.token as string)), // add token
        switchMap(() => this.userService.getUserObservable()),
        tap((user: User) => this.storeService.cacheUserData(user))
      )
  }

  public registerUserObservable(transaction_id, fakeSSN: string | null = null): Observable<any> {
    const res$ = this.agreementsService.displayAgreement()
      .pipe(
        map(data => {
          if (!data) throw new Error('Agreement not accepted')
        }),
        switchMap(() => this.register(transaction_id, fakeSSN)))

    return res$
  }


  private register(transaction_id: string, fakeSSN: string | null = null): Observable<OnboardGenericData> {
    const previousLang = this.storeService.getCachedUser()?.language
    const body = { transaction_id }
    let headers = new HttpHeaders()

    if (fakeSSN) {
      headers = headers.append('fakeSSN', fakeSSN.toString())
    }

    return this.recaptchaV3Service.execute('authShoutlyRegister')
      .pipe(
        switchMap(recaptchaToken => {
          headers = headers.append('g-recaptcha-response', recaptchaToken)
          return this.api.registerUser(body, headers)
        }),
        catchError((err: HttpErrorResponse) => {
          this.snackBar.open(_(err.error.err_mess), null, { panelClass: ['shoutly-snack-bar', 'error'] })

          if (err?.error?.err_type === 'RegistrationFailed') {
            this.router.navigate(['/auth/login'], { queryParamsHandling: 'merge' })
          }

          throw err
        }),
        tap((data: AuthUserWrapper) => this.tokenService.setJwtToken(data.token as string)), // add token
        tap((data: AuthUserWrapper) => this.storeService.cacheUserData(data.user)),
        switchMap((data: AuthUserWrapper) => this.userService.updateUserData({ ...data.user, language: previousLang })),
        switchMap(user => this.getExtendedOrganizationData(user)),
        tap(() => this.router.navigate(['/onboarding'], { queryParamsHandling: 'merge' })),
        tap(() => {
          this.gaService.event('sign_up', 'User', 'User signed up')
          this.snackBar.open(_('User successfuly registered. Welcome onboard!'), null, { panelClass: ['shoutly-snack-bar', 'success'] })
        })
      )
  }

  public logoutUser() {
    this.api.logoutUser()
      .pipe(
        take(1)
      )
      .subscribe({
        next: () => {
          this.clear()
          this.router.navigate(['auth/login'], { queryParamsHandling: 'merge' })
          this.snackBar.open(_('User successfuly logged out'), null, { panelClass: ['shoutly-snack-bar', 'success'] })
        },
        error: () => {
          this.clear()
          this.router.navigate(['auth/login'], { queryParamsHandling: 'merge' })
        }
      })
  }

  // cleans local storage and restore authConfig into default state
  private clear() {
    this.tokenService.removeToken()

    this.storeService.clearAllData()

    const initial = {
      auth_method: null,
      org_type: null,
      org_form: null,
      auth_type: null,
      current_step: 0
    }
    this.setAuthConfig(initial)
  }

  // Check whether the token is expired and return
  public isAuthenticated(): boolean {
    const token = this.tokenService.getToken()
    if (!token) {
      return false
    }
    if (this.tokenService.isTokenExpired(token)) {
      // it's expired
      this.logoutUser()
      return false
    }
    return true
  }

  public verifyEmail(token): Observable<User> {
    let headers = new HttpHeaders()

    return this.recaptchaV3Service.execute('emailVerifyUpdate')
      .pipe(
        switchMap(recaptchaToken => {
          headers = headers.append('g-recaptcha-response', recaptchaToken)
          return this.api.verifyEmail({ token }, headers)
        }),
        tap((data: AuthUserWrapper) => this.tokenService.setJwtToken(data.token as string)), // add token
        switchMap(() => this.userService.getUserObservable()),
        tap((user: User) => this.storeService.cacheUserData(user)),
        mergeMap((user: User) => this.organizationsService.getOrganization(user.current_org)
          .pipe(
            map(org => user)
          )
        )
      )
  }

  public resetPassword(email) {
    let headers = new HttpHeaders()

    return this.recaptchaV3Service.execute('passwordResetStore')
      .pipe(
        switchMap(recaptchaToken => {
          headers = headers.append('g-recaptcha-response', recaptchaToken)
          return this.api.resetPassword(email, headers)
        })
      )
  }

  public resetPasswordWithToken(params) {
    let headers = new HttpHeaders()

    return this.recaptchaV3Service.execute('passwordResetUpdate')
      .pipe(
        switchMap(recaptchaToken => {
          headers = headers.append('g-recaptcha-response', recaptchaToken)
          return this.api.resetPasswordWithToken(params, headers)
        })
      )
  }

  public hasValidToken(): boolean {
    return this.tokenService.hasValidToken()
  }

  public getToken() {
    return this.tokenService.getToken()
  }
  
  public getInvitationTokenInfo (token: string) {
    let headers = new HttpHeaders()

    return this.recaptchaV3Service.execute('genericInviteShow')
      .pipe(
        switchMap(recaptchaToken => {
          headers = headers.append('g-recaptcha-response', recaptchaToken)
          return this.api.getGenericInvitation(token, headers)
        })
      )
  }

  getInvitationToDepartmentTokenInfo () {
  }

  // private handleError<T>(operation = 'operation', result?: T) {
  //   return (error: any): Observable<T> => {
  //     this.snackBar.open(`Error during ${operation}: ${error.message}`, null, { panelClass: 'error' })
  //     // Let the app keep running by returning an empty result.
  //     return of(result as T)
  //   };
  // }  
}
